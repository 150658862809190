import styled from 'styled-components'

const StyledInput = styled.div`
    input, label {
        display: block;
    }
    input, textarea {
        padding: 10px 15px;
        background: var(--text);
        color: var(--dbg);
        border: 1px solid var(--componentbg);
        border-radius: 20px;
        width: 100%;
        font-size: 14px;    
        &:focus, &:hover {
            border-color: var(--special);
            outline: none;
        }
    }
    textarea {
        min-height: 150px;
    }
    label {
        padding: 5px 15px;
        font-weight: 700;
        color: var(--lbg);
    }
`

const Input = ({ label, value, onChange, textarea, ...rest}) => (
    <StyledInput {...rest}>
        <label htmlFor={label}>{label}</label>
        {textarea ? (
            <textarea name={label} value={value} onChange={(e) => onChange(e.target.value)} {...rest} />
        ) : (
            <input name={label} value={value} onChange={(e) => onChange(e.target.value)} {...rest} />
        )}
    </StyledInput>
)
export default Input