import { useState } from 'react'
import { Container, Grid, SectionTriangle, Flex } from '../components'
import Accordion from '../components/Accordion'
import { TokenomicsWrap, TokenomicsMobileWrap } from './styled'
import ChartImg from '../assets/tokenomics_desktop.png'
import ChartMobImg from '../assets/tokenomics_mob.png'

const Data = [
    {
        header: 'There will be two types of lock-ups after the Private sale:',
        content: [
            'Cliff lock-up – your tokens will be locked for 12 months.',
            'Linear lock-up – after the Cliff lock-up, tokens will be distributed in equal parts every month for a period of 12 months.'
        ]
    },
    {
        header: 'There will be two types of lock-ups after the IDO:',
        content: [
            'Cliff lock-up – your tokens will be locked for 9 months.',
            'Linear lock-up – after the Cliff lock-up, tokens will be distributed in equal parts every month for a period of 12 months.'
        ]
    },
    {
        header: 'Ecosystem & Treasury:',
        content: ['In game ecosystem maintenance']
    },
    {
        header: 'Community & Ambassadors:',
        content: [
            'Marketing campaigns',
            'Airdrops',
            'Whitelists',
            'Rewards',
        ]
    },
    {
        header: 'There will be two types of lock-ups for the Team:',
        content: [
            'Cliff lock-up – your tokens will be locked for 12 months.',
            'Linear lock-up – after the Cliff lock-up, tokens will be distributed in equal parts every month for a period of 12 months.'
        ]
    },
    {
        header: 'There will be two types of lock-ups for the Advisors:',
        content: [
            'Cliff lock-up – your tokens will be locked for 12 months.',
            'Linear lock-up – after the Cliff lock-up, tokens will be distributed in equal parts every month for a period of 12 months.'
        ]
    },
    {
        header: 'There will be two types of lock-ups for the Developers:',
        content: [
            'Cliff lock-up – your tokens will be locked for 12 months.',
            'Linear lock-up – after the Cliff lock-up, tokens will be distributed in equal parts every month for a period of 12 months.'
        ]
    },
    {
        header: 'Rewards:',
        content: [
            'In-game user rewards',
        ]
    },
]

const Options = [
    {
        percentage: 12.5,
        title: 'Private sale'
    },
    {
        percentage: 3.5,
        title: 'IDO'
    },
    {
        percentage: 29,
        title: 'Ecosystem & Treasury'
    },
    {
        percentage: 6,
        title: 'Community & Ambassadors'
    },
    {
        percentage: 10,
        title: 'Team'
    },
    {
        percentage: 6,
        title: 'Advisors & Partners'
    },
    {
        percentage: 5,
        title: 'Developers & Marketing'
    },
    {
        percentage: 28,
        title: 'Rewards'
    },
]

const Tokenomics = () => {
    const [ active, setActive ] = useState(0)
    return window.innerWidth > 1024 ? (
        <TokenomicsWrap id="tokenomics" active={active}>
            <SectionTriangle />
            <Container>
                <h1>Tokenomics</h1>
                <Grid colExact="repeat(2, minmax(0, 1fr)) minmax(0, 2.5fr) repeat(2, minmax(0, 1fr))" align="center">
                    <div>
                        {active < 4 &&
                            <>
                                <p>{Data[active].header}</p>
                                <ul>
                                    {Data[active].content.map(i => (
                                        <li key={i}>{i}</li>
                                    ))}
                                </ul>
                            </>
                        }   
                    </div>
                    <Flex column justify="space-between">
                        {Options.slice(0, 4).map((i, idx) => (
                            <div key={i.title} onClick={() => setActive(idx)}>
                                <span>{i.percentage}%</span>
                                <p>{i.title}</p>
                            </div>
                        ))}
                    </Flex>
                    <img src={ChartImg} alt="Tokenomics Info" />
                    <Flex column justify="space-between">
                        {Options.slice(4, 8).map((i, idx) => (
                            <div key={i.title} onClick={() => setActive(idx + 4)}>
                                <span>{i.percentage}%</span>
                                <p>{i.title}</p>
                            </div>
                        ))}
                    </Flex>
                    <div>
                        {active >= 4 && (
                            <>
                                <p>{Data[active].header}</p>
                                <ul>
                                    {Data[active].content.map(i => (
                                        <li key={i}>{i}</li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>
                </Grid>
            </Container>
        </TokenomicsWrap>
    ) : (
        <TokenomicsMobileWrap id="tokenomics">
            <SectionTriangle />
            <Container>
                <h1>Tokenomics</h1>
                <img src={ChartMobImg} alt="Tokenomics Info" />
                {Options.map((i, idx) => (
                    <Accordion key={i.title} title={`${i.percentage}% ${i.title}`}>
                        <h4>{Data[idx].header}</h4>
                        <ul>
                            {Data[idx].content.map(i => (
                                <li key={i}>{i}</li>
                            ))}
                        </ul>
                    </Accordion>                  
                ))}
            </Container>
        </TokenomicsMobileWrap>
    )
}
export default Tokenomics