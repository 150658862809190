import { HashLink } from 'react-router-hash-link'
import { HeroWrap } from './styled'
import Button from '../components/Button'
import { Container } from '../components'

const Hero = () => (
    <HeroWrap id="hero">
        <Container>
            <div>
                <h1>Walk <br />to the<br/>Moon</h1>
                <p>TipTop is a decentralized Move-to-Earn (M2E) app that unites fitness junkies and crypto enthusiasts in the collective effort to walk to the Moon. Built on the Solana blockchain, TipTop gamifies fitness to encourage a more active lifestyle and promote financial independence.</p>
                <HashLink onClick={() => window.scrollTo(0)} to="/contact">
                    <Button>Private Sale</Button>
                </HashLink>
            </div>
        </Container>
    </HeroWrap>
)
export default Hero