import styled from 'styled-components'
import { ReactComponent as Triangle } from '../assets/section_triangle.svg'

export const SectionTriangle = styled(Triangle)`
    position: absolute;
    top: 0;
    left: 0;
    height: 380px;
    z-index: -1;
    @media (max-width: 1740px) {
        height: 240px;
        width: auto
    }
    @media (max-width: 1600px) {
        height: 160px;
    }
`

export const Container = styled.div`
    max-width: 1300px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
`
export const Grid = styled.div`
    @media (min-width: ${({ disableAt }) => (disableAt ? `${disableAt}px` : '1024px')}) {
        display: grid;
        grid-template-columns: ${({ col, colExact }) => colExact || (col ? `repeat(${col}, minmax(0, 1fr))` : 'repeat(2, minmax(0, 1fr))')};
        grid-gap: ${({ gap }) => (gap ? `${gap}px` : '20px')};
        align-items: ${({ align }) => align || 'start'};
        justify-content: ${({ justify }) => justify || 'start'};
    }
    ${({ colXs, colXsExact }) => ((colXs || colXsExact) ? `
        @media (max-width: 600px) {
            grid-template-columns: ${colXsExact || (colXs ? `repeat(${colXs}, minmax(0, 1fr))` : 'repeat(2, minmax(0, 1fr))')}};
        }
    ` : '')}
    ${({ colXl, colXlExact }) => ((colXl || colXlExact) ? `
        @media (min-width: 1600px) {
            grid-template-columns: ${colXlExact || (colXl ? `repeat(${colXl}, minmax(0, 1fr))` : 'repeat(2, minmax(0, 1fr))')}};
        }
    ` : '')}
    @media (max-width: ${({ disableAt }) => (disableAt ? `${disableAt}px` : '1024px')}) {
        ${({ mobileGap }) => mobileGap
            && `
                & > * + * {
                    margin-top: ${mobileGap}px;
                }
        `};
        ${({ gapMobile }) => gapMobile
            && `
            & > * {
                margin-top: ${gapMobile}px;
            }
        `};
    }
`
export const SimpleLink = ({ href, children, ...rest }) => (
    <a href={href} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
    </a>
)
export const H2Line = styled.span`
    @media (min-width: 1393px) {
        position: absolute;
        top: ${({ top }) => `${top || 100}px`};
        left: 0;
        height: 2px;
        background: var(--special);
        width: calc((100vw - 1340px) / 2);
    }
`
export const BgImageDiv = styled.div`
    background-image: ${({ src }) => `url(${src})`};
`
export const Flex = styled.div`
    display: flex;
    ${({ align }) => (align ? `align-items: ${align}` : '')};
    ${({ justify }) => (justify ? `justify-content: ${justify}` : '')};
    flex-direction: ${({ column }) => column ? 'column' : 'row'};
    ${({ grow }) => grow && `flex-grow: ${grow}`};
    @media (max-width: ${({ sm, xs, md }) => (xs ? '400px' : sm ? '767px' : md ? '1024px' : 0)}) {
        display: block;
    }
`