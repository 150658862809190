import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { HashLink } from 'react-router-hash-link'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import Button from '../Button'
import { Container } from '../'
import MobNav from './MobNav'


const StyledNavbarWrap = styled.div`
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0s ease-out;
    svg {
        transition: all .25s ease-out;
    }
    ${({ isScrollStyle }) => isScrollStyle ? `
        height: 80px;
        background: linear-gradient(180deg, #001B2670 100%, var(--special) 100%);
        svg {
            height: 35px;
            margin-left: -20px;
        }
    ` : `
        height: 200px;
        background: linear-gradient(180deg, #001B26 0%, rgba(0, 0, 0, 0) 60%);
        svg {
            height: 50px;
            
        }
    `}
`

const StyledNavbar = styled.nav`
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > * + * {
        margin-left: 30px;
    }
    & > div:first-of-type > a:hover {
        border-bottom: 2px solid var(--special);
    }
    & > div:first-of-type > a {
        font-weight: 600;
        & + a {
            margin-left: 35px;
            
        }
    }
    & > div:last-of-type {
        display: flex;
        & > a + a {
            margin-left: 15px;
        }
   
    }
`

const Navbar = () => {
    const [isScrollStyle, setIsScrollStyle] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollStyle(window.scrollY > 200)
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return window.innerWidth > 1164 ? (
        <StyledNavbarWrap isScrollStyle={isScrollStyle}>
            <Container>
                <StyledNavbar>
                    <HashLink to="/#hero">
                        <Logo/>
                    </HashLink>
                    <div>
                        <HashLink to="/#vision">Vision</HashLink>
                        <HashLink to="/#how-it-works">How it Works</HashLink>
                        <HashLink to="/#roadmap">Roadmap</HashLink>
                        <HashLink to="/#tokenomics">Tokenomics</HashLink>
                    </div>
                    <div>
                        <HashLink onClick={() => window.scrollTo(0)} to="/contact">
                            <Button>Private sale</Button>
                        </HashLink>
                    </div>
                </StyledNavbar>
            </Container>
        </StyledNavbarWrap>
    ) : (
        <MobNav />
    )
}

export default Navbar