import { useState, useEffect} from 'react'
import { Container, Grid, SectionTriangle, Flex } from '../components'
import { CrewWrap } from './styled'
import {ReactComponent as WorldMap} from '../assets/world_map.svg'

const Crew = () => {
    const [points, setPoints] = useState([])
    useEffect(() => {
        const interval = setInterval(() => setPoints([
            Math.abs(Math.floor(Math.random() * (1 - 2500 + 1)) + 1),
            Math.abs(Math.floor(Math.random() * (1 - 2500 + 1)) + 1),
            Math.abs(Math.floor(Math.random() * (1 - 2500 + 1)) + 1),
            Math.abs(Math.floor(Math.random() * (1 - 2500 + 1)) + 1),
            Math.abs(Math.floor(Math.random() * (1 - 2500 + 1)) + 1),
        ]), 3000)
        return () => clearInterval(interval)
    } ,[])

    return (
        <CrewWrap id="crew" points={points}>
            <SectionTriangle />
            <Container>
                <h1>Our crew</h1>
                <Grid colExact="minmax(0, 2fr) minmax(0, 1fr)">
                    <WorldMap />
                    <div>
                        <Flex align="center">
                            <Flex align="center" justify="center">10.643</Flex>
                            <div>
                                <h3>Users</h3>
                                <p>Join other TipTop users from all over the globe.</p>
                            </div>
                        </Flex>
                        <Flex align="center">
                            <Flex align="center" justify="center">35</Flex>
                            <div>
                                <h3>Countries</h3>
                                <p>Be a part of a global TipTop community.</p>
                            </div>
                        </Flex>
                        <Flex align="center">
                            <Flex align="center" justify="center">21.4 K km</Flex>
                            <div>
                                <h3>Distance walked</h3>
                                <p>Unite with fitness junkies and crypto enthusiasts in the collective effort to walk to the Moon!</p>
                            </div>
                        </Flex>
                    </div>
                </Grid>
            </Container>
        </CrewWrap>
)}
export default Crew