import {
  BrowserRouter as Router,
  Routes,
  Route } from 'react-router-dom'
import { GlobalStyle } from './style'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import ContactPage from './pages/Contact'
import Main from './pages/Main'


function App() {
  return (
    <>
      <GlobalStyle />
      <Router>
        <Navbar />
        <Routes>
          <Route
            path="*"
            element={<Main />} 
          />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
