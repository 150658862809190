import styled from 'styled-components'

const StyledButton = styled.button`
    padding: 10px 15px;
    background: var(--special);
    color: var(--dbg);
    border-radius: 20px;
    border: 1px solid var(--special);
    appearance: none;
    box-shadow: none;
    letter-spacing: 0.2em;
    font-weight: 900;
    font-size: 14px;
    transition: all .25s ease-out;
    :hover {
        background: var(--lbg);
        border: 1px solid var(--lbg);
        cursor: pointer;
    }
    :disabled {
        cursor: not-allowed;
    }
`

const Button = ({children, ...rest}) => (
    <StyledButton {...rest}>{children}</StyledButton>
)
export default Button