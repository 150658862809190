import { useState } from 'react'
import { Container, SectionTriangle } from '../components'
import Input from '../components/Input'
import Button from '../components/Button'
import { ContactWrap, ContactFormWrap, SuccessWrap, ContactBgWrap } from './styled'

const Contact = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [response, setResponse] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const result = await fetch('https://mailer.tiptop.io/', {
                method: 'POST',
                body: JSON.stringify({
                    "subject": "TikTop.io contact form submission",
                    "email_from": email,
                    "name": name,
                    "message": message,
                    "affiliate": ""
                })
            }).then((res) => res.json())
            window.scrollTo(0, 0)
            if (result?.status) {
                setResponse('success')
                setEmail('')
                setName('')
                setMessage('')
            } else {
                setResponse('fail')
            }
        } catch(err) {
            console.error('Error while submitting form: ', err)
        }
    }

    return (
        <ContactBgWrap>
            <ContactWrap>
                <SectionTriangle />
                {response && (
                    <SuccessWrap isFail={response === 'fail'}>
                        <Container>
                            <p>{response === 'fail' ? 'There was an error, please try again.' : 'Success! Will get back to You ASAP.'}</p>
                        </Container>
                    </SuccessWrap>
                )}
                <Container>
                    <ContactFormWrap disableAt={767} mobileGap={20} gap={180}>
                        <div>
                            <h1>Contact us</h1>
                            <p>For any potential partnerships please fill out the form below</p>
                            <form onSubmit={handleSubmit}>
                                <Input label="Contact Person" value={name} onChange={setName} type="name" placeholder="Your Name" required />
                                <Input label="Email" value={email} onChange={setEmail} type="email" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" placeholder="email@example.com" required />
                                <Input textarea label="Message" value={message} onChange={setMessage} placeholder="Your Message" required />
                                <Button>Send</Button>
                            </form>
                        </div>
                    
                    </ContactFormWrap>
                </Container>
            </ContactWrap>
        </ContactBgWrap>
    )
}
export default Contact