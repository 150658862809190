import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { HashLink } from 'react-router-hash-link'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { ReactComponent as MobileHandlerClose } from '../../assets/close.svg'
import { ReactComponent as MobileHandler } from '../../assets/mobile_handle.svg'

const MobileNavbarWrap = styled.section`
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    ${({ isScrollStyle }) => isScrollStyle ? `
        height: 60px;
        & > a > svg {
            height: 30px;
        }
    ` : `
        height: 80px;
        & > a > svg {
            height: 40px;
        }
    `}
`
const MobileNavbar = styled.nav`
    background: var(--dbg);
    box-shadow: -5px 5px 20px var(--dbg);
    position: fixed;
    z-index: 99;
    top: 60px;
    right: 0;
    transition: all 1s;
    ${({ open }) => open ? `
        transform: translateX(0);
        opacity: 1;
        filter: none;
    ` : `
        transform: translateX(100%);
        opacity: 0;
        filter: blur(5px);
    `}
    & > a {
        display: block;
        padding: 10px 20px;
        &:nth-child(n + 6) {
            color: var(--special);
            font-weight: 500;
        }
    }
    & > a:last-of-type {
        padding-bottom: 20px;
    }
`
const MobWrap = styled.div`
    overflow-x: hidden;
`

const Navbar = () => {
    const [open, setOpen] = useState(false)
    const [isScrollStyle, setIsScrollStyle] = useState(false)

    const node = useRef()

    const clickOutside = (e) => {
        if(node.current.contains(e.target)) {
          return;
        } 
        setOpen(false)
    }

    useEffect(() => {
        document.addEventListener('mousedown', clickOutside);
        const handleScroll = () => {
            setIsScrollStyle(window.scrollY > 200)
        }
        window.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('mousedown', clickOutside);
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    return (
        <MobWrap>
            <MobileNavbarWrap isScrollStyle={isScrollStyle}>
                <a href="#hero">
                    <Logo />
                </a>
                {open ? (
                    <MobileHandlerClose height="20px" onClick={() => setOpen(!open)} />
                ) : (
                    <MobileHandler onClick={() => setOpen(!open)} />
                )}
            </MobileNavbarWrap>
            <MobileNavbar open={open} ref={node} isScrollStyle={isScrollStyle}>
                <HashLink to="/#about">About</HashLink>
                <HashLink to="/#how-it-works">Products</HashLink>
                <HashLink to="/#roadmap">Roadmap</HashLink>
                <HashLink to="/#tokenomics">Tokenomics</HashLink>
                <HashLink to="/#team">Team</HashLink>
                <HashLink href="/contact">
                    Private sale
                </HashLink>
                {/* <a href="" disabled>
                    Public sale
                </a> */}
                
            </MobileNavbar>
        </MobWrap>
    )
}
export default Navbar