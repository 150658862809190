import { Container, Grid, SectionTriangle } from '../components'
import { WorksWrap } from './styled'
import PhonesImg from '../assets/works_phone.png'
import GoogleImg from '../assets/google_play.png'

const Works = () => (
    <WorksWrap id="how-it-works">
        <SectionTriangle />
        <Container>
            <h1>How it works</h1>
            <Grid colExact="minmax(0, 1fr) minmax(0, 2fr)" disableAt={767}>
                <img src={PhonesImg} alt="phones" loading="lazy" />
                <div>
                    <div>
                        <h3>Run</h3>
                        <p>Grab your favorite shoe NFT from the TipTop marketplace, press GO, and start moving.</p>
                    </div>
                    <div>
                        <h3>Earn</h3>
                        <p>Earn tokens by walking, jogging, or running – every single step you take increases your earnings.</p>
                    </div>
                    <div>
                        <h3>Repeat</h3>
                        <p>Keep working out every day, try out different shoe NFTs, and discover the meaning of a truly sustainable crypto income!</p>
                    </div>
                    <a href="https://google.com">
                        <img src={GoogleImg} alt="Google Play" />
                    </a>
                </div>
            </Grid>
        </Container>
    </WorksWrap>
)
export default Works