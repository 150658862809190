import { Container, Grid, SectionTriangle } from '../components'
import { VisionWrap } from './styled'
import PhonesImg from '../assets/vision_phones.png'

const Vision = () => (
    <VisionWrap id="vision">
        <SectionTriangle />
        <Container>
            <h1>Vision</h1>
            <Grid>
                <div>
                    <p>Our Move-to-Earn (M2E) app was designed to ensure that every step towards our journey to the Moon is both generously rewarded and has positive well-being effects.</p>
                    <p>Through the pursuit of a common goal, TipTop also seeks to vividly illustrate the power of decentralized problem-solving on the road to collective prosperity in crypto.</p>
                    {/* <SimpleLink href="">Whitepaper</SimpleLink> */}
                </div>
                <img src={PhonesImg} alt="phones" loading="lazy" />
            </Grid>
        </Container>
    </VisionWrap>
)
export default Vision