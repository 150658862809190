  import Hero from './Hero'
  import Vision from './Vision'
  import HowItWorks from './Works'
  import Crew from './Crew'
  import Roadmap from './Roadmap'
  import Tokenomics from './Tokenomics'
  import Contact from './ContactUs'
  import { FirstSectionsWrap } from './styled'
  
  
const Main = () => (
    <div>
        <FirstSectionsWrap>
        <div />
        <Hero />
        <Vision />
        <HowItWorks />
        </FirstSectionsWrap>
        <Crew />
        <Roadmap />
        <Tokenomics />
        <Contact />
    </div>
);
  
  export default Main;
  