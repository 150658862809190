import styled from 'styled-components'
import Logo from '../assets/logo.svg'
import { HashLink } from 'react-router-hash-link'
// import Facebook from '../assets/socialMediaIcons/Facebook.svg'
// import Github from '../assets/socialMediaIcons/Github.svg'
// import Instagram from '../assets/socialMediaIcons/Instagram.svg'
// import LinkedIn from '../assets/socialMediaIcons/LinkedIn.svg'
// import Reddit from '../assets/socialMediaIcons/Reddit.svg'
// import Telegram from '../assets/socialMediaIcons/Telegram.svg'
import Twitter from '../assets/socialMediaIcons/Twitter.svg'
import Discord from '../assets/socialMediaIcons/DiscordIcon.svg'
import FooterBG from '../assets/bg_triangle.png'
import { SimpleLink, Flex } from './'



const FooterWrap = styled.footer`
box-shadow: 0px 20px 80px rgba(0, 7, 10, 0.5);
padding: 70px 40px;
background-image: url(${FooterBG});
background-size: contain;
background-position: right bottom;
background-repeat: no-repeat;
background-color: #C2474F;
@media(max-width:767px){
    background-size: auto;
    padding: 50px 20px;
}
& > div:first-of-type > div {
    text-align: center;
}
& > div > div:first-of-type {
    margin-bottom: 70px;
}
& > div > div:nth-child(2) {
    font-weight: 600;
    font-size: 16px;
   
    a {
        padding: 0 15px;
    }
    @media(max-width:767px){
        display: flex;
        flex-direction: column;
        a {
            padding: 5px 0;
        }
    }
    
}
& > div > div:last-of-type > div:first-of-type {
    margin-top: 130px;
    a {
        margin: 7px;
        padding: 10px;
        box-shadow: 0px 20px 80px rgba(0, 7, 10, 0.3);
        background-color: #C2474F;
        border-radius: 100%; 
    }
    @media(max-width:767px){
        margin-top: 20px;
        width: 240px;
        a {
            display: inline-block;
            margin-top: 7px;
        }
    }
}
& > div > div:last-of-type > div:first-of-type  {
    @media(max-width:1300px) {
       & > div:last-of-type {
         margin-top: 50px;
       } 
    }
    @media(max-width:767px){
        display: inline-block;
        & > div:last-of-type  {
            margin-top: 0;
            
        }
    }
    p {
        margin-right: 25px;
        margin-top: -15px;
        @media(max-width:767px){
            margin-top: 50px;
            margin-right: 0;
        }
    }
    
}
& > div > div:last-of-type> div:last-of-type {
    p {
        margin-right: 25px;
        font-size: 14px;
    }
    margin-top: -15px;
    @media(max-width:1300px){
        margin-top: 60px;
    }
    
    @media(max-width:767px){
        p {
        margin-right: 10px;
        font-size: 12px;
        }
    }
}
`


const Footer = () => {
    const socialMediaLinks = [
        {
            img: Twitter,
            alt: 'twitter',
            href: 'https://mobile.twitter.com/Tiptop_Official',
        },
        {
            img: Discord,
            alt: 'discord',
            href: 'https://discord.gg/xkNHtY468N',
        },
        // {
        //     img: Reddit,
        //     alt: 'reddit',
        //     href: 'https://www.reddit.com',
        // },
        // {
        //     img: Facebook,
        //     alt: 'facebook',
        //     href: 'https://www.facobook.com',
        // },
        // {
        //     img: Youtube,
        //     alt: 'YouTube',
        //     href: 'https://www.facobook.com'
        // },
        // {
        //     img: LinkedIn,
        //     alt: 'linkedIn',
        //     href: 'https://www.linkedIn.com'
        // },
        // {
        //     img: Telegram,
        //     alt: 'telegram',
        //     href: 'https://www.telegram.com'
        // },
        // {
        //     img: Instagram,
        //     alt: 'instagram',
        //     href: 'https://www.instagram.com'
        // },
        // {
        //     img: Github,
        //     alt: 'github',
        //     href: 'https://www.github.com'
        // }
    ]
    const pageLinks = [
        {
            href: '#vision',
            text: 'Vision'
        },
        {
            href: '#how-it-works',
            text: "How it works"
        },
        {
            href: '#crew',
            text: "Our crew",
        },
        {
            href: '#roadmap',
            text: 'Roadmap',
        },
        {
            href: '#tokenomics',
            text: 'Tokenomics',
        },
        {
            href: 'contact',
            text: 'Contact us'
        }
    ]
    return (
        <FooterWrap>
            <div>
                <div>
                    <img src={Logo} alt='TipTop' width={170} />
                </div>
                <div>
                    {pageLinks.map(({ href, text }) => <HashLink key={href} to={`/${href}`}>{text}</HashLink>)}
                </div>
                <div>
                    <div>
                        <div>
                            {socialMediaLinks.map(({ href, img, alt }) =>
                                <SimpleLink key={href} href={href}>
                                    <img src={img} alt={alt} />
                                </SimpleLink>)}
                        </div>
                    </div>
                    <Flex>
                        <p>&copy; 2022 TipTop</p>
                        {/* <p>Terms & Conditions </p> */}
                        {/* <p>Privacy Policy</p> */}
                    </Flex>
                </div>
            </div>
        </FooterWrap >
    )
}

export default Footer