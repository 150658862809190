import { useState, useRef } from 'react'
import { Container, SectionTriangle } from '../components'
import { RoadmapWrap, YearWrap, RoadmapContent } from './styled'
import TriangleBg from '../assets/bg_triangle_top.png'

const Years = [2022, 2023, 2024]
const Content = [
    {
         q: 4,
        content: [
            'Introduce TipTop social channels',
            'Launch TipTop App',
            'TipTop token sale',
            'Marketplace'
        ]
    },
    {
        q: 1,
        content: [
            'Token swap feature TIP/TOP & TOP/SOL',
            'Multiple languages',
            'Marketing campaigns'
        ]
    },
    {
        q: 2,
        content: [
            'Movement data analytics',
            'Metaverse partnership integration',
        ],
    },
    {
        q: 3,
        content: [
            'CEX listing',
            'DEX listing',
            'Fashion brand collab'
        ],
    },
    {
        q: 4,
        content: [
            'Special events item minting (Christmas, Halloween, etc.)',
            'Governance poll to vote in-app updates'
        ],
    },
    {
        q: 1,
        content: [
            'Player challenge system',
            'Leaderboards, rankings'
        ],
    },
    {
        q: 2,
        content: [
            'Ambassador challenge contest',
            'New in-game items'
        ],
    },
    {
        q: 3,
        content: [
            'Player challenge contest',
            'Leaderboard contest'
        ],
    },
    {
        q: 4,
        content: [
            'Special digital collectibles',
            'Sneaker rent feature'
        ],
    }
    
]

const Roadmap = () => {
    const [year, setYear] = useState(1)
    const scrollRef = useRef()

    const handleYearChange = (yr) => {
        if(yr !== year) {
            const scrollWidth = scrollRef?.current?.offsetWidth
            const toScroll = window.innerWidth > 600 ? yr === 2 ? scrollWidth / 4 : scrollWidth : scrollWidth
            scrollRef.current.scrollLeft = (yr > year ? toScroll : -toScroll) * year
            setYear(yr)
        }
    }

    return (
        <RoadmapWrap id="roadmap">
            <SectionTriangle />
            <img src={TriangleBg} alt="triangle" />
            <Container>
                <h1>Roadmap</h1>
                <YearWrap active={year}>
                    {Years.map((i, idx) => <button key={i} onClick={() => handleYearChange(idx + 1)}>{i}</button>)}
                </YearWrap>
                {window.innerWidth > 600 ? (
                    <RoadmapContent ref={scrollRef}>
                        {Content.map((i, idx) =>
                            <div key={idx}>
                                <span>Q{i.q}</span>
                                <ul key={idx}>
                                    {i.content.map(item => 
                                        <li key={item}>{item}</li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </RoadmapContent>
                ) : (
                    <RoadmapContent ref={scrollRef}>
                        <div>
                            {Content.slice(0, 1).map((i, idx) =>
                                <div key={idx}>
                                    <span>Q{i.q}</span>
                                    <ul key={idx}>
                                        {i.content.map(item => 
                                            <li key={item}>{item}</li>
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div>
                            {Content.slice(1, 5).map((i, idx) =>
                                <div key={idx}>
                                    <span>Q{i.q}</span>
                                    <ul key={idx}>
                                        {i.content.map(item => 
                                            <li key={item}>{item}</li>
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div>
                            {Content.slice(5, 9).map((i, idx) =>
                                <div key={idx}>
                                    <span>Q{i.q}</span>
                                    <ul key={idx}>
                                        {i.content.map(item => 
                                            <li key={item}>{item}</li>
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </RoadmapContent>
                )}
            </Container>
        </RoadmapWrap>
    )
}
export default Roadmap