import styled, { css } from 'styled-components'
import { Grid, Flex } from '../components'
import SneakerA from '../assets/Sneaker_A.png'
import HeroBg from '../assets/hero_bg.png'
import TriangleBg from '../assets/bg_triangle.png'

const BackgroundColor = css`
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`

export const FirstSectionsWrap = styled.div`
    position: relative;
    & > div:first-child {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url(${HeroBg});
        background-size: auto 100%;
        background-position: right center;
        background-repeat: no-repeat;
        z-index: -1;
        @media (max-width: 1200px) {
            background-size: auto 50%;
            background-position: right top;
        }
        @media (max-width: 767px) {
            background-size: auto 40%;
            background-position: 200px top;
        }
        @media (max-width: 560px) {
            background-size: auto 50%;
            background-position: -250px top;
        }
    }
`

const SectionBase = css`
    position: relative;
    padding: 160px 0 20px;
    h1 {
        margin-bottom: 20px;
    }
    @media (max-width: 1740px) {
        padding: 90px 0 20px;
    }
    @media (max-width: 1600px) {
        padding: 50px 0 20px;
    }
`

export const HeroWrap = styled.section`
    padding: 200px 0 150px;
    background-image: url(${SneakerA});
    background-size: 60% auto;
    background-position: right center;
    background-repeat: no-repeat;
    h1 {
        font-size: 150px;
        line-height: 1;
        mix-blend-mode: color-dodge;
        opacity: 70%;
        color: var(--text);
    }
    & > div > div {
        @media (min-width: 1024px) {
            max-width: 500px;
        }
        p {
            margin: 50px 0 20px;
        }
    }
    @media (max-width: 767px) {
        padding: 280px 0 40px;
        h1 {
            font-size: 100px;
        }
        background-size: 100% auto;
        background-position: right 0;
    }
    @media (max-width: 560px) {
        background-position: right 0;
        h1 {
            font-size: 64px;
        }
        svg {
            width: 70%;
            p {
                margin: 30px 0;
            }
        }
    }
    @media (max-width: 560px) {
        background-position: center 50px;
    }
`

export const VisionWrap = styled.section`
    ${SectionBase};
    p {
        margin-bottom: 20px;
    }
    a {
        color: var(--lbg);
        text-decoration: underline;
    }
    @media (max-width: 560px) {
        img {
            width: 100%;
        }
    }
`

export const ContactUsWrap = styled.section`
    ${SectionBase};
    button {
        margin-top: 20px;
    }
    background-image: url(${TriangleBg});
    background-position: bottom right;
    background-repeat: no-repeat;
    img {
        width: 100%;
    }
    @media (max-width: 767px) {
        background-size: 200% auto;
        img {
            margin: 20px auto 0;
            width: 80%;
            display: block;
        }
    }
`

export const WorksWrap = styled.section`
    ${SectionBase};
    padding-bottom: 0 !important;
    color: var(--dbg);
    h1 {
        margin: 0;
    }
    & > div > div > img {
        width: 100%;
        position: relative;
        left: -70px;
    }
    & > div > div > div {
        margin-top: 20px;
        & > div {
            margin-bottom: 30px;
        }
    }
    h3 {
        font-weight: bold;
        margin-bottom: 5px;
    }
    &::before {
        content: '';
        background: var(--special);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: -2;
    }
    @media (max-width: 767px) {
        padding-bottom: 0;
        ${Grid} {
            display: flex;
            flex-direction: column-reverse;
            & > img {
                left: -40px;
            }
        }
    }
`

export const CrewWrap = styled.section`
    ${SectionBase};
    & > div svg {
        width: 100%;
        path {
            transition: all 0.5s;
            ${({ points }) => {
                const pointsNames = points.map(i => `&:nth-child(${i})`)
                return pointsNames.toString()
            }} {
                fill: #fff;
            }
        }
    }
    ${Flex} {
        h3 {
            font-weight: bold;
            margin-bottom: 5px;
        }
        & > div:first-child {
            height: 150px;
            width: 150px;
            background: var(--special);
            border-radius: 50%;
            flex-shrink: 0;
            font-weight: bold;
            font-size: 24px;
            margin-right: 20px;
        }
        & + div {
            margin-top: 20px;
        }
    }
    @media (max-width: 600px) {
        padding-bottom: 60px;
        ${Grid} > svg {
            display: none;
        }
    }
`

export const WhitepaperWrap = styled.section`
    padding: 100px 0;
    background: linear-gradient(269.91deg, #000000 0.76%, #141414 100.14%);
    h2 {
        margin-bottom: 10px;
    }
    button {
        margin-top: 20px;
    }
    @media (min-width: 1024px) {
        p { 
            max-width: 50%;
        }
    }
    @media (max-width: 1024px) {
        padding: 40px 0;
    }
`

export const TokenomicsWrap = styled.section`
    ${SectionBase};
    color: var(--text);
    img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        z-index: 1;
        position: relative;
        flex-wrap: no-wrap;
    }
    & > div {
        position: relative;
        z-index: 1;
    }
    ${Grid} > div {
        padding: 40px 0;
        p, span {
            cursor: pointer;
        }
        span {
            font-size: 24px;
            font-weight: 900;
        }
        p {
            font-weight: 700;
            margin-bottom: 10px;
        }
        &:nth-child(2) {
            p, span {
                text-align: right;
                display: block;
            }
        }
        ${({ active }) => `
            &:nth-child(${active < 4 ? 2 : 4}) {
                & > div:nth-child(${active < 4 ? active + 1 : active - 3}) {
                    p, span {
                        color: var(--lbg);
                    }
                }
            }
        `}
    }
    @media (min-width: 767px) {
        &::after {
            content: '';
            position: absolute;
            right: 0;
            left: 0;
            bottom: 0;
            height: 215px;
            z-index: 0;
            background: var(--dbg);
        }
        ${BackgroundColor};
        &::before {
            z-index: -1;
            background: var(--special);
        }
    }
    @media (max-width: 767px) {
        background: linear-gradient(to bottom left, var(--special) 50%, var(--special) 50%, var(--dbg) 50%, var(--dbg) 100%);
    }
`
export const TokenomicsMobileWrap = styled.section`
    ${SectionBase};
    position: relative;
    z-index: 1;
    color: var(--text);
    background: linear-gradient(to bottom left, var(--special) 50%, var(--special) 50%, var(--dbg) 50%, var(--dbg) 100%);
    & > svg {
        z-index: 2;
    }
    & > div {
        z-index: 3;
        position: relative;
    }
    img {
        width: 70%;
        height: auto;
        display: block;
        margin: 0 auto;
    }
    h4 {
        margin-bottom: 20px;
    }
    @media (max-width: 500px) {
        img {
            width: 100%;
        }
    }
`

export const YearWrap = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    button {
        border: 0;
        background: none;
        border-radius: 0;
        padding: 10px 20px 10px 10px;
        font-weight: bold;
        font-size: 24px;
        cursor: pointer;
        opacity: 0.5;
        &:hover {
            color: var(--text);
        }
        &:nth-child(${({ active }) => active}) {
            opacity: 1;
        }
    }
    @media (max-width: 1024px) {
        margin: 50px 0 80px;
    }
    @media (max-width: 600px) {
        margin: 40px 0;
    }
`

export const RoadmapContent = styled.div`
    overflow-x: scroll;
    &, ul {
       white-space: nowrap;
    }
    & > div {
        display: inline-block;
        vertical-align: top;
        width: 25%;
        padding-left: 20px;
        li {
            white-space: normal;
        }
    }
    span {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
    }
    // Hide scrollbar
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    @media (max-width: 600px) {
        & > div {
            display: inline-block;
            vertical-align: top;
            width: 100%;
            padding: 0;
            ul {
                padding-left: 20px;
            }
            & > div + div {
                margin-top: 20px;
            }
        }
    }
`

export const RoadmapWrap = styled.section`
    ${SectionBase};
    position: relative;
    color: var(--dbg);
    transition: all .5s;
    min-height: 710px;
    & > img {
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
    }
    ${BackgroundColor};
    &::before {
        z-index: -1;
        background: var(--special);
    }
    @media (max-width: 600px) {
        & > img {
            height: 50%;
        }
    }
`
export const ContactBgWrap = styled.section`
    padding-top: 80px;
    background-image: url(${HeroBg});
    background-size: auto 100%;
    background-position: right center;
    background-repeat: no-repeat;
`
export const ContactWrap = styled.div`
    ${SectionBase};
    padding-bottom: 200px;
`
export const ContactFormWrap = styled(Grid)`
    form {
        & > div {
            margin-bottom: 20px;
        }
        button {
            margin: 0 auto;
            display: block;
        }
    }
    & > div {
        &:first-child {
            p {
                padding-bottom: 30px;
            }
        }
    }
    button {
        width: 100%;
    }
`
export const SuccessWrap = styled.div`
    position: relative;
    z-index: 1;
    background: ${({ fail }) => fail ? 'var(--special)' : 'var(--lbg)'};
    padding: 20px 0;
`