import { Link } from 'react-router-dom'
import { Container, Grid, SectionTriangle } from '../components'
import Button from '../components/Button'
import { ContactUsWrap } from './styled'
import SneakerImg from '../assets/Sneaker_A_back.png'

const ContactUs = () => (
    <ContactUsWrap id="contact">
        <SectionTriangle />
        <Container>
            <Grid align="center">
                <div>
                    <h1>Contact us</h1>
                    <p>Questions? Doubts? Fears?</p>
                    <p>Don’t worry, we got you covered.</p>
                    <br />
                    <p>Head to our official Discord channel and drop us a line – we’ll do our best to answer all of your questions.</p>
                    <Link to="/contact">
                        <Button>Contact Us</Button>
                    </Link>
                </div>
                <img src={SneakerImg} alt="sneakers" loading="lazy" />
            </Grid>
        </Container>
    </ContactUsWrap>
)
export default ContactUs